import { DynamicPage, DynamicPageProps, getDynamicPageProps } from '~/templates/pages';
import { NodeLocalStorage } from '~/lib/storage';
import DynamicLayout from '~/templates/pages/components/DynamicLayout/DynamicLayout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps = (context: any) => {
    // important for the require to be here for nextJS to work with FS/localStorage
    context.ls = new NodeLocalStorage(new (require('node-localstorage').LocalStorage)('./scratch'));
    return getDynamicPageProps(context);
};

export default function SlugPage(props: DynamicPageProps) {
    return (
        <DynamicLayout pageProps={props}>
            <DynamicPage {...props} />
        </DynamicLayout>
    );
}
